<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="editRecordPrompt" persistent max-width="600px">
      <lom-president-assign
        :pageDescription="editPageDescription"
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordIndex="recordToEdit"
        :record="record"
        v-if="editRecordPrompt"
      ></lom-president-assign>
    </v-dialog>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="75%">
      <events-preview
        pageTitle="Events Preview"
        pageDescription="Events Preview"
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        :recordData="selectedData"
        v-if="previewRecordPrompt"
      ></events-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-footer :fixed="true" :absolute="false" :padless="true">
        <v-card
          flat
          tile
          width="100%"
          color="#8950FC"
          v-if="tableData1.length > 0"
        >
          <v-container>
            <!-- <v-divider></v-divider> -->
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" lg="12" md="12">
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  target="_blank"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-footer>

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p><span class="text-danger">*</span> indicates required field</p>
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" sm="12" lg="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="ZoneCode"
                  :rules="ZoneCodeRules"
                  :items="ZoneCodeOptions"
                  :loading="ZoneCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
                <v-btn
                  @click.prevent="resetForm"
                  color="#00a4ef"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-form
            ref="form2"
            v-model="valid2"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3>{{ tableData1.length }} records found.</h3>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                  Delete
                </v-btn> -->
                <p></p>
                <json-excel
                  v-if="
                    tableData1.length > 0 &&
                    tableOptions1.JsonDownloadButtonFlag == true
                  "
                  :name="tableOptions1.ExcelFileName"
                  :data="tableData1"
                  :fields="tableOptions1.JsonExcelFields"
                >
                  <v-btn color="info" class="mr-4"> Download </v-btn>
                </json-excel>
              </v-col>
              <p></p>
            </v-row>
            <v-container class="py-0">
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    class="font-size-h6 mr-3 white--text"
                    v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                    @click.prevent="generateReportExcel"
                    :loading="ExcelLoadingFlag"
                    target="_blank"
                    color="#ff7f50"
                  >
                    Generate Report
                  </v-btn>
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    class="font-size-h6 mr-3 white--text"
                    v-if="ExcelFileName != '' && ExcelFileFlag == true"
                    :href="ExcelFileName"
                    target="_blank"
                    color="#2fb65a"
                  >
                    <v-icon dark> mdi-download </v-icon> Download Report
                  </v-btn>
                </v-col>
              </v-row>
              <p></p>
            </v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search records here"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  loading-text="Loading... Please wait"
                  v-model="selected"
                  :search="search"
                  :loading="TableLoadingFlag"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.NewZoneId="{ item }">
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="ZoneCodeOptionsLoading"
                      :items="ZoneCodeOptions"
                      :rules="ZoneCodeRules"
                      v-model="item.NewZoneId"
                      dense
                      clearable
                      required
                      outlined
                    ></v-select>
                  </template>
                  <template v-slot:item.NewRegionId="{ item }">
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="RegionCodeOptionsLoading"
                      :items="RegionCodeOptions"
                      :rules="RegionCodeRules"
                      v-model="item.NewRegionId"
                      dense
                      clearable
                      required
                      outlined
                    ></v-select>
                  </template>
                  <template v-slot:item.NewZoneId="{ item }">
                    <v-select
                      :reduce="(option) => option.value"
                      v-on:change="changeZoneConfirmAlert(item)"
                      :loading="ZoneCodeOptionsLoading"
                      :items="ZoneCodeOptions"
                      v-model="item.NewZoneId"
                      dense
                      clearable
                      required
                      outlined
                    ></v-select>
                  </template>
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.ProfilePic="{ item }">
                    <img width="100" height="100" :src="item.ProfilePic" />
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom v-if="Modules.PreviewEventFlag == true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="blue"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="Modules.AssignPresidentFlag == true"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="success"
                          @click="editAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-shield-account
                        </v-icon>
                      </template>
                      <span> Assign President </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.EditFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="editData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-edit
                        </v-icon>
                      </template>
                      <span> Edit </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.DeleteFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="red"
                          @click="deleteConfirmAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span> Delete </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import LomPresidentAssign from "@/view/pages/erp/lom-president/LomPresidentAssign.vue";
import EventsPreview from "@/view/pages/erp/events/EventsPreview.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    LomPresidentAssign,
    EventsPreview,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      ProgressLoadingFlag: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      DownloadFlag: 0,
      ExcelFileName: "",
      excel_fields: {
        "Lom Id": "LomId",
        "Zone Id": "ZoneId",
        "Lom Name": "LomName",
        "President Name": "LomPresidentFullName",
        "LOM NAME": "LomName",
        "Mobile No": "LomPresidentMobileNo",
        "Email Id": "LomPresidentEmailid",
      },

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      RegionCodeRules: [(v) => !!v || "Area is required"],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      RoleId: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        this.RoleId = RoleId;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    JciYearCode: function () {
      console.log("watch JciYearCode");
      this.getZoneCodeOptions();
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_lom",
        Action: "create_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    // code 1
    validate() {
      if (this.$refs.form1.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/events/edit";
        var id = tr.JciEventId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    resetForm() {
      this.$refs.form1.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form1.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        this.record = tr;
        this.recordToEdit = LomId;
        this.editPageDescription =
          "Assign 2021 LOM President for " + tr.LomName;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to modifiy";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    pageData() {
      console.log("refreshPageData called");
      this.getJciYearCodeOptions();
      this.getRegionCodeOptions();
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.RegionCodeOptionsLoading = true;
      var selectbox1_source = "RegionCode";
      var selectbox1_destination = "RegionCodeOptions";
      var selectbox1_url = "api/region/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      this.LoadingFlag = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/zone/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.ExcelFileFlag = false;
        this.Modules = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-lom/lom-lists";
        var upload = {
          UserInterface: 4,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.TableContent;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            console.log({ records });

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              var options = records.TableOptions;
              console.log({ options });
              thisIns.tableOptions1 = options;
              thisIns.ExcelDownloadFlag = options.ExcelDownloadButtonFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      // var validate1 = this.$refs.form1.validate();
      var FromDate = "2021-08-04";
      var ToDate = "2021-08-05";
      var validate1 = true;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;
        this.ExcelFileFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/reports/directory-information/generate-excel";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.ExcelFileFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Event Type <b> " + tr.OrganizationTypeName + "</b>";
        htmlTxt += "<br/> Portfolio <b> " + tr.PortfolioShortCode + "</b>";
        htmlTxt += "<br/> Event name <b> " + tr.JciEventName + "</b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr.JciEventId);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(EventId) {
      console.log("deleteData is called");

      console.log("EventId=" + EventId);

      if (EventId > 0) {
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/destroy";
        var upload = {
          UserInterface: 1,
          EventId: EventId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "Event Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var validate2 = this.$refs.form2.validate();
      console.log("validate2=" + validate2);

      // this.rows = [];

      if (validate1 && validate2) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-lom/lom-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          Loms: this.tableData1,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Year should not be empty. ";
        }
        if (!validate2) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    changeZoneConfirmAlert(tr) {
      console.log("changeZoneConfirmAlert called");
      var message = "";

      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var temp_zone_code = tr.NewZoneId;

        if (temp_zone_code > 0) {
          console.log("temp_zone_code=" + temp_zone_code);
          var idx = this.ZoneCodeOptions.map((e) => e.value).indexOf(
            temp_zone_code
          );
          console.log("idx=" + idx);
          var newZonename = "";

          if (idx >= 0) {
            var tr1 = this.ZoneCodeOptions[idx];
            console.log({ tr1 });
            newZonename = tr1.text;
          }

          var htmlTxt = "";
          htmlTxt += "<br/> Zone Name <b> " + tr.ZoneName + "</b>";
          htmlTxt += "<br/> Lom Name <b> " + tr.LomName + "</b>";
          htmlTxt += "<br/> New Zone name <b> " + newZonename + "</b>";

          Swal.fire({
            title: "Do you want to continue?",
            text: "Please verify all the information before proceeding.",
            icon: "warning",
            html: htmlTxt,
            showCancelButton: true,
            confirmButtonText: `Continue`,
            confirmButtonColor: "#d33",
            cancelButtonText: `Cancel`,
            cancelButtonColor: "#3085d6",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.changeZoneCode(tr);
            } else {
              this.sweetAlert("error", "You cancelled the process", true);
            }
          });
        } else {
          if (temp_zone_code <= 0 || temp_zone_code == "") {
            message += "New Zone Code should not be embty. ";
          }
          this.sweetAlert("error", message, false);
        }
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to change Zone. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    changeZoneCode(tr) {
      console.log("changeZoneCode is called");

      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var LomCode = tr.LomId;
        var NewZoneCode = tr.NewZoneId;
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-lom/lom-zone-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          Lom: LomCode,
          NewZone: NewZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to change Zone. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    var CurrentJciYearId = this.$session.get("CurrentJciYearId");
    // CurrentJciYearId = 11;
    CurrentJciYearId =
      CurrentJciYearId == (null || undefined) ? "" : CurrentJciYearId;
    console.log({ CurrentJciYearId });
    // this.JciYearCode = CurrentJciYearId;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>